import React, {useContext, useEffect, useState, useReducer} from "react";
import DashboardHeader from "./dashboard_header";
import SideMenu from "./dashboard_menu";
import DashboardFooter from "./dashboard_footer";
import {HomeContext} from "../dashboard_pages/HomeProvider";
import {useAppName, useBaseUrl, usePostData} from "../../utils/utils";
import localforage from "localforage";
import {Link, useNavigate} from "react-router-dom";
import localForage from "localforage";



interface Service {
    service_title: string;
    service_subtitle: string;
    service_image: string;
}

const DMainTags: React.FC = () => {

    const navigate = useNavigate()

    const initialState = {
        category: "",
        fName: "",
        lName: "",
        clinic: "",
        greeting: "",
        currentTime: ""
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case "SET_CATEGORY":
                return {...state, category: action.payload}

            case "SET_FNAME":
                return {...state, fName: action.payload}

            case "SET_LNAME":
                return {...state, lName: action.payload}

            case "SET_CLINIC":
                return {...state, clinic: action.payload}

            case "SET_GREETING":
                return {...state, greeting: action.payload}

            case "SET_TIME":
                return {...state, currentTime: action.payload}

            default:
                return state
        }


    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const appName= useAppName()

    useEffect(() => {

        const fetchTime = async () => {
            try {

                const response = await fetch('http://worldtimeapi.org/api/timezone/Africa/Nairobi')

                const data = await response.json();

                dispatch({type: "SET_TYPE", payload: data.datetime})

                const currentHour: any = new Date(data.datetime).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', hour12: false, hour: 'numeric' });

                if (currentHour >= 0 && currentHour < 12) {
                    dispatch({type: "SET_GREETING", payload: "Good Morning"})
                } else if (currentHour >= 12 && currentHour < 18) {
                    dispatch({type: "SET_GREETING", payload: "Good Afternoon"})
                } else {
                    dispatch({type: "SET_GREETING", payload: "Good Evening"})
                }

            } catch (error) {

                console.error('Error fetching time:', error);
            }
        };

        fetchTime();
    }, []);

    useEffect(() => {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            // Change the data-layout attribute
            rootElement.setAttribute('data-layout', 'semi-box');
        }

        document.title = appName

        localforage.getItem("clinic_name").then((clin: any)=>{
            dispatch({type: "SET_CLINIC", payload: clin})
        })

        localforage.getItem("fname").then((frs: any)=>{
            dispatch({type: "SET_FNAME", payload: frs})
        })

        localforage.getItem("lname").then((lrs: any)=>{
            dispatch({type: "SET_LNAME", payload: lrs})
        })

        localforage.getItem("category").then((cat: any)=> {
            dispatch({type: "SET_CATEGORY", payload: cat})
        })
    }, []);

    const services: Service[] = [
        {
            service_title: 'Service 1',
            service_subtitle: 'Subtitle 1',
            service_image: 'img-1.png',
        },
        {
            service_title: 'Service 2',
            service_subtitle: 'Subtitle 2',
            service_image: 'img-2.png',
        },
        {
            service_title: 'Service 3',
            service_subtitle: 'Subtitle 3',
            service_image: 'img-3.png',
        },
        {
            service_title: 'Service 4',
            service_subtitle: 'Subtitle 4',
            service_image: 'img-4.png',
        },
        {
            service_title: 'Service 5',
            service_subtitle: 'Subtitle 5',
            service_image: 'img-5.png',
        },
        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },
        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },

        {
            service_title: 'Service 6',
            service_subtitle: 'Subtitle 6',
            service_image: 'img-3.png',
        },
    ];

    const medicalCategories: string[] = ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'];

    const shuffleArray = <T extends unknown>(array: T[]): T[] => {
        const shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
    };

    const shuffledServices = shuffleArray(services);

    const rows: JSX.Element[][] = [];
    let currentRow: JSX.Element[] = [];

    shuffledServices.forEach((service, index) => {
        const { service_title, service_subtitle, service_image } = service;

        const card = (
            <div className="col" key={index}>

            </div>
        );

        currentRow.push(card);

        if (currentRow.length === 5 || index === shuffledServices.length - 1) {
            rows.push(currentRow);
            currentRow = [];
        }
    });

    const {content} = useContext(HomeContext)

    const [showButton, setShowButton] = useState(true);

    const topFunction = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    };

    const ScrollFunction = () => {
        if (document.documentElement.scrollTop > 20) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    };

    window.onscroll = ScrollFunction;
    
    return(
        <>
            <div id="layout-wrapper">
                <DashboardHeader></DashboardHeader>


                <SideMenu></SideMenu>

                <div className="vertical-overlay">

                </div>

                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0  ">{state.clinic} - {state.category}'s Staff Access &nbsp;
                                        </h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a
                                                    href="javascript: void(0);">Dashboards</a></li>
                                                <li className="breadcrumb-item active">Facility</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className={"row mt-4 text-center"}>
                                <div className={"col-12"}>
                                    <h3>{state.greeting} - {state.fName} {state.lName}</h3>
                                </div>
                            </div>

                            <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                            <h6>Welcome to uzima Healthcare! Here you have access to various tools to assist you in your professional activities.</h6>
                                    </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-xxl-12">

                                    <h4 className="card-title mb-0 flex-grow-1">My Shortcuts  <i className={"bx bx-reset"}> </i></h4>

                                    <div className={"row mt-2 mb-2"}>

                                        <div className={"col"}>
                                            <Link to={"/institution/ehr"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/record.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Patient EHR</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/clinics/launch"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/clinic.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">My Facility</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/resources"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/resources.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Resources</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/collaborations"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/collaborations.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Find Partners</h5>

                                                </div>
                                            </Link>

                                        </div>

                                        <div className={"col"}>
                                            <Link to={"/institution/notifications"} className="card card-animate">
                                                <div className="card-body p-4 text-center">
                                                    <div className="mx-auto avatar-md mb-3">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/notifications.png`}
                                                            alt=""
                                                            className="img-fluid rounded-circle"
                                                        />
                                                    </div>
                                                    <h5 className="card-title mb-1">Notifications</h5>

                                                </div>
                                            </Link>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="row project-wrapper">
                                <div className="col-xxl-6">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-center">
                                            <h4 className="card-title flex-grow-1 mb-0">Active Collaborations</h4>
                                            <div className="flex-shrink-0">
                                                <a href="javascript:void(0);" className="btn btn-soft-info btn-sm">Download Report</a>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="table-responsive table-card">
                                                <table className="table table-nowrap table-centered align-middle">
                                                    <thead className="bg-light text-muted">
                                                    <tr>
                                                        <th scope="col">Activity</th>
                                                        <th scope="col">Lead Partner</th>
                                                        <th scope="col">Progress</th>
                                                        <th scope="col">Partner</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col" style={{width: "10%"}}>Due Date</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    <tr>
                                                        <td className="fw-medium">Activity One</td>
                                                        <td>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} className="avatar-xxs rounded-circle me-1" alt="" />
                                                                <a href="javascript: void(0);" className="text-reset">User One</a>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 me-1 text-muted fs-13">53%</div>
                                                                <div className="progress progress-sm  flex-grow-1" style={{width: "68%"}}>
                                                                    <div className="progress-bar bg-primary rounded" role="progressbar" style={{width: "53%"}} aria-valuenow={53} aria-valuemin={0} aria-valuemax={100}> </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="avatar-group flex-nowrap">
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><span className="badge badge-soft-warning">Inprogress</span></td>
                                                        <td className="text-muted">06 Sep 2023</td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="fw-medium">Activity Two</td>
                                                        <td>
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-2.jpg`} className="avatar-xxs rounded-circle me-1" alt="" />
                                                                <a href="javascript: void(0);" className="text-reset">User Ten</a>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 text-muted me-1">0%</div>
                                                                <div className="progress progress-sm flex-grow-1" style={{width: "68%;"}}>
                                                                    <div className="progress-bar bg-primary rounded" role="progressbar" style={{width: "0%"}} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}> </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="avatar-group">
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-5.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                                <div className="avatar-group-item">
                                                                    <a href="javascript: void(0);" className="d-inline-block">
                                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-6.jpg`} alt="" className="rounded-circle avatar-xxs" />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><span className="badge badge-soft-danger">Pending</span></td>
                                                        <td className="text-muted">13 Nov 2024</td>
                                                    </tr>{/* end tr */}
                                                    <tr > </tr>
                                                    <tr>  </tr>
                                                    <tr>   </tr>
                                                    </tbody>{/* end tbody */}
                                                </table>{/* end table */}
                                            </div>

                                            <div className="align-items-center mt-xl-3 mt-4 justify-content-between d-flex">
                                                <div className="flex-shrink-0">
                                                    <div className="text-muted">Showing <span className="fw-semibold">5</span> of <span className="fw-semibold">25</span> Results
                                                    </div>
                                                </div>
                                                <ul className="pagination pagination-separated pagination-sm mb-0">
                                                    <li className="page-item disabled">
                                                        <a href="#" className="page-link">←</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a href="#" className="page-link">1</a>
                                                    </li>
                                                    <li className="page-item active">
                                                        <a href="#" className="page-link">2</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a href="#" className="page-link">3</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a href="#" className="page-link">→</a>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>{/* end card body */}
                                    </div>
                                </div>
                                <div className="col-xxl-6">
                                    <div className="card">
                                        <div className="card-header align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">Facility Staff</h4>
                                            <div className="flex-shrink-0">
                                                {/*<div className="dropdown card-header-dropdown">
                                                    <a className="text-reset dropdown-btn" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="fw-semibold text-uppercase fs-12">Sort by: </span><span className="text-muted">Last 30 Days<i className="mdi mdi-chevron-down ms-1"></i></span>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-end">
                                                        <a className="dropdown-item" href="#">Today</a>
                                                        <a className="dropdown-item" href="#">Yesterday</a>
                                                        <a className="dropdown-item" href="#">Last 7 Days</a>
                                                        <a className="dropdown-item" href="#">Last 30 Days</a>
                                                        <a className="dropdown-item" href="#">This Month</a>
                                                        <a className="dropdown-item" href="#">Last Month</a>
                                                    </div>
                                                </div>*/}
                                            </div>
                                        </div>{/* end card header */}

                                        <div className="card-body">

                                            <div className="table-responsive table-card">
                                                <table className="table table-borderless table-nowrap align-middle mb-0">
                                                    <thead className="table-light text-muted">
                                                    <tr>
                                                        <th scope="col">Member</th>
                                                        <th scope="col">Hours</th>
                                                        <th scope="col">Tasks</th>
                                                        <th scope="col">Status</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-1.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Two</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Category 1</p>
                                                                </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">110h : <span className="text-muted">150h</span>
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            258
                                                        </td>
                                                        <td style={{width:"5%"}}>
                                                            <div id="radialBar_chart_1" data-colors='["--vz-primary"]' data-chart-series={50} className="apex-charts" dir="ltr"> </div>
                                                        </td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-4.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Three</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Radiologist</p>
                                                                </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">01h : <span className="text-muted">15h</span></h6>
                                                        </td>
                                                        <td>
                                                            85
                                                        </td>
                                                        <td>
                                                            <div id="radialBar_chart_4" data-colors='["--vz-warning"]' data-chart-series={25} className="apex-charts" dir="ltr"> </div>
                                                        </td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-6.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Four</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Category Nine</p>
                                                                </div>
                                                        </td>
                                                        <td>
                                                            <h6 className="mb-0">76h : <span className="text-muted">150h</span></h6>
                                                        </td>
                                                        <td>
                                                            69
                                                        </td>
                                                        <td>
                                                            <div id="radialBar_chart_5" data-colors='["--vz-primary"]' data-chart-series={60} className="apex-charts" dir="ltr"> </div>
                                                        </td>
                                                    </tr>{/* end tr */}
                                                    <tr>
                                                        <td className="d-flex">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-5.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                                <div>
                                                                    <h5 className="fs-13 mb-0">User Five</h5>
                                                                    <p className="fs-12 mb-0 text-muted">Category Twenty</p>
                                            </div>
                                        </td>

                                        <td>
                                            <h6 className="mb-0">123h : <span className="text-muted">150h</span>
                                            </h6>
                                        </td>
                                        <td>
                                            658
                                        </td>
                                        <td>
                                            <div id="radialBar_chart_6" data-colors='["--vz-success"]' data-chart-series={85} className="apex-charts" dir="ltr"> </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="d-flex">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/users/avatar-3.jpg`} alt="" className="avatar-xs rounded-3 me-2" />
                                                <div>
                                                    <h5 className="fs-13 mb-0">User Six</h5>
                                                    <p className="fs-12 mb-0 text-muted">Category Thirty</p>
                                                </div>
                                        </td>
                                        <td>
                                            <h6 className="mb-0">11h : <span className="text-muted">10h</span>
                                            </h6>
                                        </td>
                                        <td>
                                            125
                                        </td>
                                        <td>
                                            <div id="radialBar_chart_7" data-colors='["--vz-primary"]' data-chart-series={70} className="apex-charts" dir="ltr"> </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

                        </div>
                    </div>

                </div>


                <DashboardFooter></DashboardFooter>
            </div>

            <button onClick={topFunction} className="btn btn-danger btn-icon" id="back-to-top">
                <i className="ri-arrow-up-line"></i>
            </button>

            <div id="preloader">
                <div id="status">
                    <div className="spinner-border text-primary avatar-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>

            <div className="customizer-setting d-none d-md-block">
                <div className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2" data-bs-toggle="offcanvas"
                     data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <i className='mdi mdi-spin mdi-cog-outline fs-22'></i>
                </div>
            </div>

            <div className="offcanvas offcanvas-end border-0" tabIndex={-1} id="theme-settings-offcanvas">
                <div className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
                    <h5 className="m-0 me-2 text-white">Uzima Customizer</h5>

                    <button type="button" className="btn-close btn-close-white ms-auto" id="customizerclose-btn" data-bs-dismiss="offcanvas" aria-label="Close"> </button>
                </div>
                <div className="offcanvas-body p-0">
                    <div data-simplebar className="h-100">
                        <div className="p-4">
                            <h6 className="mb-0 fw-bold text-uppercase">Layout</h6>
                            <p className="text-muted">Choose your layout</p>

                            <div className="row gy-3">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout01" name="data-layout" type="radio" value="vertical" className="form-check-input" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout01">
                                    <span className="d-flex gap-1 h-100">
                                        <span className="flex-shrink-0">
                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                            </span>
                                        </span>
                                        <span className="flex-grow-1">
                                            <span className="d-flex h-100 flex-column">
                                                <span className="bg-light d-block p-1"> </span>
                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                            </span>
                                        </span>
                                    </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Vertical</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout02" name="data-layout" type="radio" value="horizontal" className="form-check-input" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout02">
                                                <span className="d-flex h-100 flex-column gap-1">
                                                    <span className="bg-light d-flex p-1 gap-1 align-items-center">
                                                        <span className="d-block p-1 bg-soft-primary rounded me-1"> </span>
                                                        <span className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"> </span>
                                                        <span className="d-block p-1 pb-0 px-2 bg-soft-primary"> </span>
                                                    </span>
                                                    <span className="bg-light d-block p-1"> </span>
                                                    <span className="bg-light d-block p-1 mt-auto"> </span>
                                                </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Horizontal</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout03" name="data-layout" type="radio" value="twocolumn" className="form-check-input" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout03">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1">
                                                            <span className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Two Column</h5>
                                </div>
                                {/* end col */}

                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input id="customizer-layout04" name="data-layout" type="radio" value="semibox" className="form-check-input" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="customizer-layout04">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0 p-1">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column pt-1 pe-2">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Semi Box</h5>
                                </div>
                                {/* end col */}
                            </div>

                            <h6 className="mt-4 mb-0 fw-bold text-uppercase">Color Scheme</h6>
                            <p className="text-muted">Choose Light or Dark Scheme.</p>

                            <div className="colorscheme-cardradio">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-mode" id="layout-mode-light" value="light" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="layout-mode-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check card-radio dark">
                                            <input className="form-check-input" type="radio" name="data-layout-mode" id="layout-mode-dark" value="dark" />
                                                <label className="form-check-label p-0 avatar-md w-100 bg-dark" htmlFor="layout-mode-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-soft-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-soft-light d-block p-1"> </span>
                                                                <span className="bg-soft-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-visibility">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Visibility</h6>
                                <p className="text-muted">Choose show or Hidden sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-visibility" id="sidebar-visibility-show" value="show" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-visibility-show">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0 p-1">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column pt-1 pe-2">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Show</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-visibility" id="sidebar-visibility-hidden" value="hidden" />
                                                <label className="form-check-label p-0 avatar-md w-100 px-2" htmlFor="sidebar-visibility-hidden">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column pt-1 px-2">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Hidden</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="layout-width">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Layout Width</h6>
                                <p className="text-muted">Choose Fluid or Boxed layout.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-width" id="layout-width-fluid" value="fluid" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="layout-width-fluid">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Fluid</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-width" id="layout-width-boxed" value="boxed" />
                                                <label className="form-check-label p-0 avatar-md w-100 px-2" htmlFor="layout-width-boxed">
                                                    <span className="d-flex gap-1 h-100 border-start border-end">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Boxed</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="layout-position">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Layout Position</h6>
                                <p className="text-muted">Choose Fixed or Scrollable Layout Position.</p>

                                <div className="btn-group radio" role="group">
                                    <input type="radio" className="btn-check" name="data-layout-position" id="layout-position-fixed" value="fixed" />
                                        <label className="btn btn-light w-sm" htmlFor="layout-position-fixed">Fixed</label>

                                        <input type="radio" className="btn-check" name="data-layout-position" id="layout-position-scrollable" value="scrollable" />
                                            <label className="btn btn-light w-sm ms-0" htmlFor="layout-position-scrollable">Scrollable</label>
                                </div>
                            </div>
                            <h6 className="mt-4 mb-0 fw-bold text-uppercase">Topbar Color</h6>
                            <p className="text-muted">Choose Light or Dark Topbar Color.</p>

                            <div className="row">
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar" id="topbar-color-light" value="light" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="topbar-color-light">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-light d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Light</h5>
                                </div>
                                <div className="col-4">
                                    <div className="form-check card-radio">
                                        <input className="form-check-input" type="radio" name="data-topbar" id="topbar-color-dark" value="dark" />
                                            <label className="form-check-label p-0 avatar-md w-100" htmlFor="topbar-color-dark">
                                                <span className="d-flex gap-1 h-100">
                                                    <span className="flex-shrink-0">
                                                        <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                            <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                        </span>
                                                    </span>
                                                    <span className="flex-grow-1">
                                                        <span className="d-flex h-100 flex-column">
                                                            <span className="bg-primary d-block p-1"> </span>
                                                            <span className="bg-light d-block p-1 mt-auto"> </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                    </div>
                                    <h5 className="fs-13 text-center mt-2">Dark</h5>
                                </div>
                            </div>

                            <div id="sidebar-size">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Size</h6>
                                <p className="text-muted">Choose a size of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-default" value="lg" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-default">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-compact" value="md" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-compact">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Compact</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-small" value="sm" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small (Icon View)</h5>
                                    </div>

                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-small-hover" value="sm-hover" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-size-small-hover">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1">
                                                                <span className="d-block p-1 bg-soft-primary mb-2"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Small Hover View</h5>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-view">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar View</h6>
                                <p className="text-muted">Choose Default or Detached Sidebar view.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style" id="sidebar-view-default" value="default" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-view-default">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Default</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-layout-style" id="sidebar-view-detached" value="detached" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-view-detached">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span className="bg-light d-flex p-1 gap-1 align-items-center px-2">
                                                            <span className="d-block p-1 bg-soft-primary rounded me-1"> </span>
                                                            <span className="d-block p-1 pb-0 px-2 bg-soft-primary ms-auto"> </span>
                                                            <span className="d-block p-1 pb-0 px-2 bg-soft-primary"> </span>
                                                        </span>
                                                        <span className="d-flex gap-1 h-100 p-1 px-2">
                                                            <span className="flex-shrink-0">
                                                                <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                    <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <span className="bg-light d-block p-1 mt-auto px-2"> </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Detached</h5>
                                    </div>
                                </div>
                            </div>
                            <div id="sidebar-color">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Color</h6>
                                <p className="text-muted">Choose a color of Sidebar.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse"
                                             data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-light" value="light" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-color-light">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span
                                                                className="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                                                                <span className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary">  </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Light</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient.show">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-dark" value="dark" />
                                                <label className="form-check-label p-0 avatar-md w-100" htmlFor="sidebar-color-dark">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Dark</h5>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-link avatar-md w-100 p-0 overflow-hidden border collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient" aria-expanded="false" aria-controls="collapseBgGradient">
                                             <span className="d-flex gap-1 h-100">
                                                <span className="flex-shrink-0">
                                                    <span className="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                                        <span className="d-block p-1 px-2 bg-soft-light rounded mb-2"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                        <span className="d-block p-1 px-2 pb-0 bg-soft-light"> </span>
                                                    </span>
                                                </span>
                                                <span className="flex-grow-1">
                                                    <span className="d-flex h-100 flex-column">
                                                        <span className="bg-light d-block p-1"> </span>
                                                        <span className="bg-light d-block p-1 mt-auto"> </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </button>
                                        <h5 className="fs-13 text-center mt-2">Gradient</h5>
                                    </div>
                                </div>
                                {/* end row */}

                                <div className="collapse" id="collapseBgGradient">
                                    <div className="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">

                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient" value="gradient" />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle" htmlFor="sidebar-color-gradient">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient"> </span>
                                                </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-2" value="gradient-2" />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                       htmlFor="sidebar-color-gradient-2">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-2"> </span>
                                                </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-3" value="gradient-3" />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                       htmlFor="sidebar-color-gradient-3">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-3"> </span>
                                                </label>
                                        </div>
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-4" value="gradient-4" />
                                                <label className="form-check-label p-0 avatar-xs rounded-circle"
                                                       htmlFor="sidebar-color-gradient-4">
                                                    <span
                                                        className="avatar-title rounded-circle bg-vertical-gradient-4"> </span>
                                                </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="sidebar-img">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Sidebar Images</h6>
                                <p className="text-muted">Choose a image of Sidebar.</p>

                                <div className="d-flex gap-2 flex-wrap img-switch">
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-none" value="none" />
                                            <label className="form-check-label p-0 avatar-sm h-auto"
                                                   htmlFor="sidebarimg-none">
                                    <span
                                        className="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                                        <i className="ri-close-fill fs-20"> </i>
                                    </span>
                                            </label>
                                    </div>

                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-01" value="img-1" />
                                            <label className="form-check-label p-0 avatar-sm h-auto"
                                                   htmlFor="sidebarimg-01">
                                                <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-1.jpg"} alt="" className="avatar-md w-auto object-cover" />
                                            </label>
                                    </div>

                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-02" value="img-2" />
                                            <label className="form-check-label p-0 avatar-sm h-auto"
                                                   htmlFor="sidebarimg-02">
                                                <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-2.jpg"} alt="" className="avatar-md w-auto object-cover" />
                                            </label>
                                    </div>
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-03" value="img-3" />
                                            <label className="form-check-label p-0 avatar-sm h-auto"
                                                   htmlFor="sidebarimg-03">
                                                <img src={process.env.PUBLIC_URL + "assets/images/sidebar/img-3.jpg"} alt="" className="avatar-md w-auto object-cover" />
                                            </label>
                                    </div>
                                    <div className="form-check sidebar-setting card-radio">
                                        <input className="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-04" value="img-4" />
                                            <label className="form-check-label p-0 avatar-sm h-auto"
                                                   htmlFor="sidebarimg-04">
                                                <img src="assets/images/sidebar/img-4.jpg" alt="" className="avatar-md w-auto object-cover" />
                                            </label>
                                    </div>
                                </div>
                            </div>

                            <div id="preloader-menu">
                                <h6 className="mt-4 mb-0 fw-bold text-uppercase">Preloader</h6>
                                <p className="text-muted">Choose a preloader.</p>

                                <div className="row">
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-preloader" id="preloader-view-custom" value="enable" />
                                                <label className="form-check-label p-0 avatar-md w-100"
                                                       htmlFor="preloader-view-custom">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                    {/* <div id="preloader"> */}
                                                    <div id="status"
                                                         className="d-flex align-items-center justify-content-center">
                                                        <div className="spinner-border text-primary avatar-xxs m-auto"
                                                             role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Enable</h5>
                                    </div>
                                    <div className="col-4">
                                        <div className="form-check sidebar-setting card-radio">
                                            <input className="form-check-input" type="radio" name="data-preloader" id="preloader-view-none" value="disable" />
                                                <label className="form-check-label p-0 avatar-md w-100"
                                                       htmlFor="preloader-view-none">
                                                    <span className="d-flex gap-1 h-100">
                                                        <span className="flex-shrink-0">
                                                            <span className="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                                <span
                                                                    className="d-block p-1 px-2 bg-soft-primary rounded mb-2"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                                <span className="d-block p-1 px-2 pb-0 bg-soft-primary"> </span>
                                                            </span>
                                                        </span>
                                                        <span className="flex-grow-1">
                                                            <span className="d-flex h-100 flex-column">
                                                                <span className="bg-light d-block p-1"> </span>
                                                                <span className="bg-light d-block p-1 mt-auto"> </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </label>
                                        </div>
                                        <h5 className="fs-13 text-center mt-2">Disable</h5>
                                    </div>
                                </div>

                            </div>
                            {/* end preloader-menu */}

                        </div>
                    </div>

                </div>
                <div className="offcanvas-footer border-top p-3 text-center">
                    <div className="row">
                        <div className="col">
                            <button type="button" className="btn btn-light w-100" id="reset-layout">Defaults</button>
                        </div>
                    </div>
                </div>
            </div>


    </>
    )
}

export default DMainTags;


