import React, {useEffect, useReducer, useState} from 'react'

interface patientInfo {
    patient: any
}

const PatientCollaborations: React.FC<patientInfo> = ({patient}) => {

    useEffect(()=>{
        document.title = "Collaborations related to: " + patient.first_name
    }, [])

    const [collaborations, setCollaborations] = useState<any[]>([])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        pageStep: 0,
        pageCount: 7,
        selectedPage: 1
    }

    const reducer = (state: any, action: any) => {

        switch (action.type) {

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_SUB_STEP":
                return {...state, subStep: action.payload}

            case "SET_CARD_STEP":
                return {...state, cardStep: action.payload}

            case "SET_PAGE_STEP":
                return {...state, pageStep: action.payload}

            case "SET_SELECTED_PAGE":
                return {...state, selectedPage: action.payload}

            default:
                return state

        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <>

            <div className="container-fluid">

                {state.step === 0 && (
                    <>
                        <div className="row g-4 mb-3">
                            <div className="col-sm-auto">
                                <div>
                                    <button className="btn btn-success">
                                        <i className="ri-add-line align-bottom me-1"> </i>
                                        Add New
                                    </button>
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="d-flex justify-content-sm-end gap-2">
                                    <div className="search-box ms-2">
                                        <input type="text" className="form-control" placeholder="Search..." />
                                        <i className="ri-search-line search-icon"> </i>
                                    </div>

                                    <div className="choices" tabIndex={0} role="listbox" >
                                        <div>
                                            <select className="form-control" data-choices="" data-choices-search-false="" hidden={false} tabIndex={-1} data-choice="active">
                                                <option value="week" >
                                                    Weekly
                                                </option>

                                                <option value="week" >
                                                    Monthly
                                                </option>

                                                <option value="week" >
                                                    Yearly
                                                </option>
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 project-card">
                                <div onClick={(e)=>{
                                    dispatch({type: "SET_STEP", payload: 1})
                                }} className="card card-height-100">
                                    <div className="card-body">
                                        <div className="d-flex flex-column h-100">
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <p className="text-muted mb-4">Updated 1hrs ago</p>
                                                </div>
                                                <div className="flex-shrink-0">
                                                    <div className="d-flex gap-1 align-items-center">
                                                        <button type="button" className="btn avatar-xs mt-n1 p-0 favourite-btn">
                                                        <span className="avatar-title bg-transparent fs-15">
                                                            <i className="ri-star-fill"> </i>
                                                        </span>
                                                        </button>
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="true">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                     viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                                     stroke-width="2" stroke-linecap="round"
                                                                     stroke-linejoin="round"
                                                                     className="feather feather-more-horizontal icon-sm">
                                                                    <circle cx="12" cy="12" r="1"> </circle>
                                                                    <circle cx="19" cy="12" r="1"> </circle>
                                                                    <circle cx="5" cy="12" r="1"> </circle>
                                                                </svg>
                                                            </button>

                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <a onClick={(e)=>{
                                                                    dispatch({type: "SET_STEP", payload: 1})
                                                                }} className="dropdown-item">
                                                                    <i className="ri-eye-fill align-bottom me-2 text-muted"> </i> View
                                                                </a>

                                                                <a onClick={(e)=>{
                                                                    dispatch({type: "SET_STEP", payload: 2})
                                                                }} className="dropdown-item">
                                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"> </i> Participate
                                                                </a>

                                                                <div className="dropdown-divider"> </div>
                                                                <a onClick={(e)=>{
                                                                    dispatch({type: "SET_STEP", payload: 3})
                                                                }} className="dropdown-item" >
                                                                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"> </i> Leave Collaboration
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-2">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-sm">
                                                    <span className="avatar-title rounded p-2">
                                                        <img src={`${process.env.PUBLIC_URL}/assets/images/clamp.png`} alt=""
                                                             className="img-fluid p-1" />
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h6 className="mb-1 fs-15">
                                                        <a onClick={(e)=>{
                                                            dispatch({type: "SET_STEP", payload: 1})
                                                        }} className="text-body">
                                                            {patient.first_name} {patient.last_name}'s short treatment
                                                        </a>
                                                    </h6>
                                                    <p className="text-muted text-truncate-two-lines mb-3">
                                                        This bot is being treated for a case of severe, resistant botscitis...
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-auto">
                                                <div className="d-flex mb-2">
                                                    <div className="flex-grow-1">
                                                        <div>Tasks</div>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <div><i
                                                            className="ri-list-check align-bottom me-1 text-muted"></i> 18/42
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="progress progress-sm animated-progress">
                                                    <div className="progress-bar bg-success" role={"progressbar"}
                                                         aria-valuenow={34} aria-valuemin={0} aria-valuemax={100}
                                                         style={{width: "34%;"}}></div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div className="card-footer bg-transparent border-top-dashed py-2">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1">
                                                <div className="avatar-group">
                                                    <a href="javascript: void(0);" className="avatar-group-item"
                                                       data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"
                                                       aria-label="Darline Williams" data-bs-original-title="Darline Williams">
                                                        <div className="avatar-xxs">
                                                            <img src="assets/images/users/avatar-2.jpg" alt=""
                                                                 className="rounded-circle img-fluid" />
                                                        </div>
                                                    </a>
                                                    <a href="javascript: void(0);" className="avatar-group-item"
                                                       data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"
                                                       data-bs-original-title="Add Members">
                                                        <div className="avatar-xxs">
                                                            <div
                                                                className="avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary">
                                                                +
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    <i className="ri-calendar-event-fill me-1 align-bottom"></i> 10 Jul, 2021
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div className="row g-0 text-center text-sm-start align-items-center mb-4">
                            <div className="col-sm-6">
                                <div>
                                    <p className="mb-sm-0 text-muted">Showing <span className="fw-semibold">1</span> to <span
                                        className="fw-semibold">10</span> of <span
                                        className="fw-semibold text-decoration-underline">12</span> entries</p>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <li className={`page-item ${state.selectedPage <= 1 ? "disabled" : ""}`} onClick={(e)=>{
                                        if(state.selectedPage > 1){
                                            dispatch({type: "SET_SELECTED_PAGE", payload: state.selectedPage - 1})
                                        }
                                    }}>
                                        <a href="#" className="page-link">Previous</a>
                                    </li>

                                    {Array.from({length: state.pageCount}, (_, index) => (
                                        <li key={index + 1} className={`page-item ${ index + 1 === state.selectedPage ? "active" : ""}`} onClick={(e)=>{
                                            dispatch({type: "SET_SELECTED_PAGE", payload: index + 1})
                                        }}>
                                            <a href="#" className="page-link">{index+1}</a>
                                        </li>
                                    ))}
                                    <li className={`page-item ${state.selectedPage >= state.pageCount ? "disabled" : ""}`} onClick={(e)=>{
                                        if(state.selectedPage < state.pageCount){
                                            dispatch({type: "SET_SELECTED_PAGE", payload: state.selectedPage + 1})
                                        }
                                    }}>
                                        <a href="#" className="page-link">Next</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </>
                )}

                {state.step === 1 && (
                    <>

                    </>
                )}

            </div>

        </>
    )
}

export default PatientCollaborations