import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import localforage from "localforage";


export const useAppName = () => {

    return "Uzima Health Care"
}

export const useBaseUrl = () =>{
    return "https://ckolaty.pythonanywhere.com/"
}

export const useGetData = async (url: any) => {

    try{

        const resp = await axios.get(url)

        const {status, message, data} = resp.data

        if(status === "success"){

            return data;

        }else{

            let msg = "Server says: " + message

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server says: " + message,
            });

            return

        }

    }catch{

        let msg = "Unable to establish Server - Client connection, are you offline?"

        await Swal.fire({
            icon: "warning",
            title: "Warning",
            text: msg
        });

    }

}

export const usePostData = async (url: any, dat: any) => {

    try{

        const resp = await axios.post(url, dat)

        const {status, message, data} = resp.data

        if(status === "success"){

            return data

        }else{

            await Swal.fire({
                icon: "error",
                title: "Error",
                text: "Server says: " + message,
            });

            return

        }

    }catch{

        let msg = "Unable to establish Server - Client connection, are you offline?"

        await Swal.fire({
            icon: "warning",
            title: "Warning",
            text: msg
        });

        return
    }

}

export const useFacilityServices = () => {

    const FetchServices = async () => {
        const url = `${useBaseUrl()}/facilities/service-list`

        const data = await useGetData(url)

        return data
    }

    return FetchServices()

}

export const useCounties = async ()=>{

    const url = `${useBaseUrl()}/facilities/get-counties`

    const formaData = new FormData()

    formaData.append("selector", "9999")

    let resp: any;

    resp = await usePostData(url, formaData);

    return resp

}

export const useTowns = async (countyId: any) => {

    const url = `${useBaseUrl()}/facilities/get-post-towns`

    const formaData = new FormData()

    formaData.append("county_id", countyId)

    let resp: any;

    resp = await usePostData(url, formaData);

    return resp

}

export const useBreadcrumbs = (clinicName: any, service:any, item: any, subItem: any) => {

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{clinicName} - {service}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">{item}</a></li>
                                <li className="breadcrumb-item active">{subItem}</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export const useStaffRoles = async ()=>{
    const FetchRoles = async () => {
        const url = `${useBaseUrl()}/facilities/user-roles`

        return await useGetData(url)
    }

    return FetchRoles
}

export const useTokenChecker = async (token: any, staffId: any, facilityId: any) => {

    const url = `${useBaseUrl()}/facilities/check-token`

    const formData = new FormData()

    formData.append("token", token)

    formData.append("staff", staffId)

    formData.append("facility", facilityId)

    let resp: any

    resp = await usePostData(url, formData)

    return resp

}

export const useFullCategories = async () => {

    const url = `${useBaseUrl()}/facilities/full-categories`
    
    let resp: any

    resp = await useGetData(url)

    return resp

}

export const useUserNames =  async () => {

    let fName: any = '';
    let lName: any = '';
    let cliniC: any = '';
    let staffId: any = '';
    let avatar: any = '';


    try{

        await( async () => {
            const [first_name, last_name, clinic, usr, avtr] = await Promise.all([
                localforage.getItem('fname'),
                localforage.getItem('lname'),
                localforage.getItem('clinic_name'),
                localforage.getItem('staff_id'),
                localforage.getItem('user_avatar'),
            ])

            fName = first_name || '';
            lName = last_name || '';
            cliniC = clinic || '';
            staffId = usr || '';
            avatar = avtr === '' || avtr === null ? 'avatar.png' : avtr;
        })()



    } catch (e) {

    }

    return ({first_name: fName, last_name: lName, clinic: cliniC, staff_id: staffId, avatar: avatar})

}

export const useSentenceCase = (word: any) => {

    return word.charAt(0).toUpperCase() + word.slice(1);

}

export const useGreeting = async () => {



    let greeting
    let type

    try {

        const response = await fetch('http://worldtimeapi.org/api/timezone/Africa/Nairobi')

        const data = await response.json();

        type = data.datetime

        const currentHour: any = new Date(data.datetime).toLocaleString('en-US', { timeZone: 'Africa/Nairobi', hour12: false, hour: 'numeric' });

        if (currentHour >= 0 && currentHour < 12) {
            greeting = "Good Morning"
        } else if (currentHour >= 12 && currentHour < 18) {
            greeting = "Good Afternoon"
        } else {
            greeting = "Good Evening"
        }

    } catch (error) {

        console.error('Error fetching time:', error);
    }

    return {greeting, type}

}

export const useDjangoToReactTime = (djangoTime: any) => {

    const djangoTimeStamp = new Date(djangoTime)

    return `${djangoTimeStamp.toLocaleDateString()} at ${djangoTimeStamp.toLocaleTimeString()}`
}

