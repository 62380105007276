import React, {useEffect, useReducer, useState} from "react";
import DashboardFooter from "../layouts/dashboard_footer";
import {Link} from "react-router-dom";
import localforage from "localforage";
import {useAppName, useBaseUrl} from "../../utils/utils";

const HomePage: React.FC = () => {

    let base_url = useBaseUrl()
    
    const appName = useAppName()

    const [burl, setBurl] = useState(base_url)

    const initialState = {
        step: 0,
        burl: base_url
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case "SET_STEP":
                return {...state, step: action.payload}

            case "SET_BURL":
                return {...state, burl: action.payload}

            default:
                return state
        }

    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(()=> {
        document.title = appName

        localforage.setItem("base_url", burl)

    }, []);

    const divStyle = {
        borderRadius: "10% "
    }

    return(
        <>

            <nav className="navbar navbar-expand-lg text-uppercase fixed-top card" style={{backgroundColor:'#F6F6F6'}}  id="mainNav">
                <div style={{backgroundColor:'#F6F6F6'}} className="container">
                    <a className="navbar-brand" href={"#page-top"}>{useAppName()}</a>
                    <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"  aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        Menu
                        <i className="fas fa-bars"> </i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item mx-0 mx-lg-1"><button onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: 0})
                            }} className={"btn btn-primary mt-2"}>Previous Step</button></li>
                            <li className="nav-item mx-0 mx-lg-1" onClick={(e)=>{dispatch({type: "SET_STEP", payload: 0})}}><a className="nav-link py-3 px-0 px-lg-3 rounded" href={"#portfolio"}>Home</a></li>
                            <li className="nav-item mx-0 mx-lg-1" onClick={(e)=>{dispatch({type: "SET_STEP", payload: 2})}}><Link className="nav-link py-3 px-0 px-lg-3 rounded" to={"/search"}>Service</Link></li>
                            <li className="nav-item mx-0 mx-lg-1" onClick={(e)=>{dispatch({type: "SET_STEP", payload: 1})}}><a className="nav-link py-3 px-0 px-lg-3 rounded" href={"#about"}>About</a></li>
                            <li className="nav-item mx-0 mx-lg-1"><button onClick={(e)=>{
                                dispatch({type: "SET_STEP", payload: state.step + 1})
                            }} className={"btn btn-primary mt-2"}>{
                                state.step!==0 && (
                                    <>Next Step
                                    </>
                                )
                            } {state.step===0 && (<>
                                Get Started
                            </>)}</button></li>

                        </ul>
                    </div>
                </div>
            </nav>

            {state.step===0 && (
                <img style={{width: "100%", height: "100%", overflow: "hidden"}} src={`${process.env.PUBLIC_URL}/assets/images/front.png`} alt="..."/>
            )}

            {state.step===1 && (
                <section className="page-section mb-0 mt-4" style={{minHeight: "100%" }} id="about">
                    <div style={{minHeight: "100%" }} className="container mt-4 text-center">

                        <br />
                        <br />

                        <h2 className="page-section-heading text-uppercase mt-4">What is {appName}?</h2>

                        <div className="divider-custom divider-light">
                            <div className="divider-custom-line"> </div>
                            <div className="divider-custom-icon"><i className="fas fa-star"> </i></div>
                            <div className="divider-custom-line"> </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col ms-auto">
                                <p className="lead mt-4">

                                    Uzima Healthcare is a collaborative platform that targets all service providers in the Health Sector.

                                </p>

                                <p className="lead mt-3">
                                    Uzima Healthcare also takes care of the health journey of patients whilst leaving patients fully in charge of their health records.
                                </p>

                                <p className="lead mt-3">

                                    Uzima Healthcare enables players in the health secor collaborate with each other, each bringing in their core expertise. The platform takes care of partnership processes and tasks, irrespective of parties legacy systems, enabling the streamlined collaborative end to end delivery of health services..
                                </p>

                                <p className="lead mt-3">
                                    <br />
                                    Need a preview? Use The following credentials to access: <br />

                                    <span style={{fontWeight: "Bold"}}>Username: guest@uzimahealthcare.co.ke </span> <br />

                                    <span style={{fontWeight: "Bold"}}>Password: password</span> <br />
                                </p>

                                <div className={"row mt-4"}>
                                    <div className={"col text-center"}>

                                        <p className={"lead mt-4"}>
                                            <br />
                                            <span style={{fontWeight: "Bold"}}> Developer Notice!</span>
                                            <br />

                                            <span style={{fontWeight: "Bold"}}>
                                                Uzima is currently deploying as a prototype in development and is not yet operationsl. Some links will not work as intended, we sincerely apologize so please do not panic, if it happens send screenshots or comments to the development team at: lynmercart@gmail.com or check back after a little while! ;-) #Uzima DevTeam
                                            </span>

                                        </p>

                                    </div>
                                </div>

                                <div className={"row mt-4"}>
                                    <div className={"col text-center"}>
                                        <button onClick={(e)=>{
                                            dispatch({type: "SET_STEP", payload: 2})
                                        }} className={"btn btn-primary"} style={{width: "60%"}}>Take me there</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {state.step === 2 && (
                <div className={"container-fluid"}>
                    <div className={"row mt-4"}>
                        <div className={"col"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col text-center"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col text-center"}>

                            <h4>Uzima Health Care Getting Started</h4>
                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col"}>

                        </div>
                    </div>
                    <div className={"row mt-4"}>
                        <div className={"col-md-2"}>

                        </div>
                        <div className={"col-md-8"}>

                            <div className={"row mt-4"}>
                                <div className={"col"}>

                                    <div className="text-center mt-4 mb-3">

                                        <Link to="/institution/newreg" className={"card card-animate"}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/register.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Registration</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className="text-center mt-4 mb-3">
                                        <Link to="/institution/newlog" className={"card card-animate"}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/login.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Login</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className="text-center mt-4 mb-3">
                                        <Link to="/institution/home" className={"card card-animate"}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/dashboards.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Dashboard Tour</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className={"col"}>
                                    <div className="text-center mt-4 mb-3">
                                        <Link to="/search" className={"card card-animate"}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/searchs.png`}
                                                        alt="" className="img-fluid"/>
                                                </div>
                                                <h5 className="card-title mb-1">Search</h5>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={"col-md-2"}>

                        </div>
                    </div>
                </div>
            )}


        </>
    )
}

export default HomePage;