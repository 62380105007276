import React, {useEffect, useState} from "react";
import DashboardHeader from "../layouts/dashboard_header";
import SideMenu from "../layouts/dashboard_menu";
import DashboardFooter from "../layouts/dashboard_footer";
import localforage from "localforage";
import InvoiceSummary from "./InvoiceSummary";

const Billing: React.FC = () => {
    const [pageTitle, setPageTitle] = useState("Finance Center")
    const [clinicName, setClinicName] = useState<any>("Facility")
    const [invoicePage, setInvoicePage] = useState("Finance Center")
    const [step, setStep] = useState(1)

    useEffect(()=>{
        document.title = "Uzima - Finance Center"
        localforage.getItem("clinic_name").then((clini)=>{
            setClinicName(clini)
        })
    }, [])

    return (
        <>
            <DashboardHeader></DashboardHeader>
            <SideMenu></SideMenu>

            <div className={"vertical-overlay"}></div>

            <div className={"main-content"}>
                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        <div className="row mt-2">
                            <div className="col-12">
                                <div
                                    className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0  ">{clinicName} - {pageTitle} &nbsp;
                                    </h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item"><a
                                                href="javascript: void(0);">Dashboards</a></li>
                                            <li className="breadcrumb-item active">Finance</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className={"row mt-2"}>
                            <div className={"col text-center"}>
                                <h3>{invoicePage}</h3>
                            </div>
                        </div>

                        {step === 1 && (
                            <div className={"row mt-3"}>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        setStep(2)
                                        setInvoicePage("Billing - Invoice Management")
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/invoice.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Invoices</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        setStep(3)
                                        setInvoicePage("Billing - Receive Payments")
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Incoming</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/expenses.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Expenses</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/freports.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Reports</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/codes.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Codes</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/insurance.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Insurance</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/plans.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Models</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-3"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Tax</h5>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}

                        {step===2 &&(
                            <>
                                <InvoiceSummary />

                                <div className={"row mt-3"}>

                                    <div className={"col-md-3"}>
                                        <div className="card card-animate"  onClick={()=>{
                                            return
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/create.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Create</h5>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-md-3"}>
                                        <div className="card card-animate"  onClick={()=>{
                                            return
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/list.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">My List</h5>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-md-3"}>
                                        <div className="card card-animate"  onClick={()=>{
                                            return
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/share.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Share</h5>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-md-3"}>
                                        <div className="card card-animate"  onClick={()=>{
                                            return
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/downloads.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Download</h5>

                                            </div>
                                        </div>
                                    </div>

                                    <div className={"col-md-3"}>
                                        <div className="card card-animate"  onClick={()=>{
                                            setStep(1)
                                            setInvoicePage("Billing Account")
                                        }}>
                                            <div className="card-body p-4 text-center">
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/invoice.png`} alt="" className="img-fluid" />
                                                </div>
                                                <h5 className="card-title mb-1">Go Back</h5>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                        )}

                        {step===3 && (
                            <div className={"row mt-3"}>

                                <div className={"col-md-4"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/income.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Receive Payment</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-4"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/income_statement.png`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Income Statement</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-4"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        return
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/loading.gif`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Pending Income</h5>

                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-4"}>
                                    <div className="card card-animate"  onClick={()=>{
                                        setInvoicePage("Billing - My Account")
                                        setStep(1)
                                    }}>
                                        <div className="card-body p-4 text-center">
                                            <div className="mx-auto avatar-md mb-3">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/receiving.gif`} alt="" className="img-fluid" />
                                            </div>
                                            <h5 className="card-title mb-1">Back</h5>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        )}

                    </div>

                </div>

                <DashboardFooter></DashboardFooter>
            </div>

            <canvas></canvas>
        </>
    )
}

export default Billing