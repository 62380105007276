import React, {useEffect, useReducer} from 'react'
import {useUserNames} from "../../../utils/utils";
import DashboardHeader from "../../layouts/dashboard_header";
import SideMenu from "../../layouts/dashboard_menu";
import DashboardFooter from "../../layouts/dashboard_footer";
import {Link} from "react-router-dom";

const PatientCenter: React.FC = () => {

    let user_info: any = {first_name: "", last_name: "", clinic: "", staff_id: "", avatar: ""}

    useEffect(()=>{

        document.title = "Uzima Patient Center"

        const GetUsers = async () => {

            const user_info = useUserNames()

        }

        user_info = GetUsers()

    }, [])

    const initialState = {
        step: 0,
        subStep: 0,
        cardStep: 0,
        header: "Uzima Patient Center",
        subHeader: "Track & manage all your patients from here.",
        showHeadings: true
    }

    const reducer = (state: any, action: any) => {

        switch(action.type){

            case 'SET_STEP':
                return {...state, step: action.payload}

            case 'SET_PAGE_HEADING':
                return {...state, header: action.payload}

            case 'SET_SUB_HEADER':
                return {...state, subHeader: action.payload}

            case 'SET_SUB_STEP':
                return {...state, subStep: action.payload}

            case 'SET_CARD_STEP':
                return {...state, cardStep: action.payload}

            case 'SET_SHOW_HEADINGS':
                return {...state, showHeadings: action.payload}

            default:
                return state
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <>

            <DashboardHeader />
            <SideMenu />

            <div className={"main-content"}>

                <div className={"page-content"}>

                    <div className={"container-fluid"}>

                        {state.showHeadings && (
                            <>
                                <div className={"row mt-4 text-center"}>
                                    <div className={"col-12"}>
                                        <h3>{state.header}</h3>
                                    </div>
                                </div>

                                <div className={"row mt-4 text-center"}>
                                    <div className={"col"}>
                                        <h6>{state.subHeader}</h6>
                                    </div>
                                </div>
                            </>
                        )}

                        {state.step === 0 && (
                            <>
                                <div className={"row mt-4"}>
                                    <div className={"col-md-3"}>

                                        <Link to={"/institution/patient-list"} className={"card card-animate"}  >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/patient.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">My Patients</h5>

                                                <br />

                                            </div>

                                        </Link>

                                    </div>
                                    <div className={"col-md-3"}>

                                        <Link to={"/institution/ehr"} className={"card card-animate"}  >

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/record.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Patient Records</h5>

                                                <br />

                                            </div>

                                        </Link>

                                    </div>
                                    <div className={"col-md-3"}>

                                        <Link to={"/institution/patient-history"} className={"card card-animate"}>

                                            <div className="card-body p-4 text-center">
                                                <br />
                                                <br />
                                                <div className="mx-auto avatar-md mb-3">
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/assets/images/laboratories.png`}
                                                        alt=""
                                                        className="img-fluid rounded-circle"
                                                    />
                                                </div>
                                                <br />
                                                <h5 className="card-title mb-1">Medication History</h5>

                                                <br />

                                            </div>

                                        </Link>

                                    </div>
                                </div>

                            </>
                        )}


                    </div>

                </div>

                <DashboardFooter />
            </div>

        </>
    )
}

export default PatientCenter